module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.gstatic.com"],"interval":300,"timeout":30000,"web":[{"name":"IBM Plex Sans","file":"https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"stoic-coder","short_name":"SC","description":"Blog about the stoic way of coding and general learnings.","start_url":"/","background_color":"#fff","display":"standalone","icons":[{"src":"/manifest-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/manifest-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-cookieconsent/gatsby-browser.js'),
      options: {"plugins":[],"cookieConsentConfig":{"categories":{"necessary":{"enabled":true,"readOnly":true},"analytics":{}},"language":{"default":"en","translations":{"en":{"consentModal":{"title":"Cookie usage","description":"The usage of cookies helps me to improve the usage of this website.","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","showPreferencesBtn":"Manage Individual preferences"},"preferencesModal":{"title":"Manage cookie preferences","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","savePreferencesBtn":"Accept current selection","closeIconLabel":"Close modal","sections":[{"title":"Type of Cookies","description":"Allowing Analytics cookies helps me to improve the website, however I would never want to impose them on you."},{"title":"Strictly Necessary cookies","description":"These cookies are essential for the proper functioning of the website and cannot be disabled.","linkedCategory":"necessary"},{"title":"Performance and Analytics","description":"These cookies collect information about how you use my website. All of the data is anonymized and cannot be used to identify you.","linkedCategory":"analytics"},{"title":"More information","description":"For any queries in relation to my policy on cookies and your choices, please contact me via email <a href=\"mailTo:uli.astoiccoder@gmail.com\">contact me</a>"}]}}}}},"googleGtagPluginConfig":{"trackingIds":["G-73J4392KZD"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
